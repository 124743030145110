/* src/pages/UserListPage.css */
.user-list-page {
  padding: 20px;
  background-color: #f5f5f5;
}

.user-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.user-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 200px;
  text-align: center;
}

.user-card a {
  text-decoration: none;
  color: inherit;
}

.user-avatar {
  width: 100%;
  height: 150px;
  border-radius: 10%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the circle */
  background-color: #ddd; /* Optional: background color in case the image is not loaded */
}

.user-info {
  padding: 10px;
}

.user-info h2 {
  font-size: 16px;
  margin: 10px 0;
  color: #1a73e8; /* Blue color for the username */
}

.user-info p {
  font-size: 14px;
  color: #666;
}
