body {
  margin: 0;
  /* background-image: url('../public/images/BG\ \(1\).jpg'); */

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.underline {
  text-decoration: underline;
}

.customStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.campaigns-container {
  display: flex;
  flex-wrap: wrap; /* Wrap items to the next line if they overflow */
  /* justify-content: space-between;  */
  justify-content: center;
}

.campaign-item {
  width: calc(50% - 10px); /* Adjust for potential margins */
  margin-bottom: 20px; /* Vertical spacing */
}
