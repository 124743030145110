@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

html {
  height: 100%;
  background-color: #e4e4e4;
}

.custom-margin-top {
  margin-top: 17.75rem;
}

.imgfull {
  background-image: url("../public/images/BG\ \(1\).jpg");
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  overflow-y: auto;
}

.container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.my-input {
  width: 100%;
  max-width: 25rem; /* Use rem units for better scalability */
  margin: 0 auto;
}

.logo-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent; /* Card background with transparency */
  /* padding: 2rem; */
  /* border-radius: 8px;  */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for card effect */
  margin-bottom: 4rem; /* Space between logo card and form */
  /* background-image: url('../public/images/BG\ \(1\).jpg');  */
  background-size: cover;
  background-position: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem; /* Adjust as necessary */
}

.form-container {
  width: 100%;
  max-width: 25rem;
}

/* .form-container {
  position: relative; 
  z-index: 1; 
  width: 100%;
  max-width: 400px; 
  margin: 0 auto; 
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9); 
  border-radius: 8px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add this CSS to your stylesheet */
.navbar {
  background-color: #e4e4e4 !important;
  padding: 0 20px;
}

.hamburger {
  visibility: hidden;
  cursor: pointer;
  /* display: flex; */
  align-items: center;
}

.hamburger span {
  /* background-color: #000; */
  display: block;
  height: 4px;
  width: 24px;
  margin: 5px 0;
  transition: all 0.3s;
}

/* .hamburger.is-active span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.hamburger.is-active span:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
} */

.menu {
  /* background-color: #2c3e50;
  padding: 5px;
  width: 180px;
  height: 100%;
  position: fixed;
  margin-left: 30px;
  border-radius: 5px;
  top: 20px;
  left: 0; */
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  background-color: #2c2c2c;
  padding: 10px;
  /* border-radius: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 17px;
  background-image: url("../public/images/sidebar.png");
  /* background-size: cover; */
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: 1;
}
.menu-list li {
  margin-left: 20px; /* Add spacing between menu items */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.menu1 {
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  background-color: #2c2c2c;
  padding: 10px;
  /* border-radius: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../public/images/sidebar.png");
  /* background-size: cover; */
  background-position: bottom;
  background-repeat: no-repeat;
}

.menu1 li {
  margin: 15px 0;
}

.menu1 .icon {
  color: #fff;
  font-size: 1.5rem;
}

.menu-list {
  list-style: none;
  padding: 0;
}

.menu-list .menu-label {
  color: #ecf0f1;
  font-weight: bold;
  margin-bottom: 20px;
}

.menu-list li {
  margin: 10px 0;
}

.menu-list li a {
  color: #ecf0f1;
  text-decoration: none;
}

.menu-list li a:hover {
  color: #3498db !important;
}

.content {
  padding: 20px;
  background-color: #ecf0f1;
  flex: 1;
}

.has-border-primary {
  position: relative; /* Ensure the pseudo-elements are positioned relative to this element */
  border: 1px solid #4a4a4a; /* Primary color */
  background-color: transparent;
  color: #4a4a4a; /* Primary color */
  padding: 10px; /* Optional padding for better text readability */
}

.has-border-primary::before {
  left: -100px;
}

.has-border-primary::after {
  right: -100px;
}

/* CSS */
body.modal-open {
  overflow: hidden;
}

.platform-details input {
  margin-top: 3px;
}

/* CSS */
body.modal-open {
  overflow: hidden;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
}

.checkbox-item label {
  margin-left: 5px;
}

.platform-details {
  margin-top: 10px;
  /* margin-left: 20px; */
}
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  /* overflow-x: auto; */
  margin-bottom: 20px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
}

.checkbox-item label {
  margin-left: 5px;
}

.platform-details {
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}
.button.is-small-custom {
  padding: 5px 15px;
  font-size: 15px;
}

.field.is-grouped {
  justify-content: center;
  margin-left: 0px !important;
}

.container {
  flex-grow: 0;
}

.logo {
  height: 47px !important;
  width: 55px !important;
  margin-top: 46px;
  margin-bottom: 100px;
  margin-left: -10px;
  margin-right: -10px;
}

.logo.is-active {
  margin-left: 0px;
  margin-right: 0px;
}

.filter-buttons {
  height: 59px;
  /* max-width: auto; */
  width: 100%;
  border: none !important;
  box-shadow: 10px -6px 7.8px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: #fafafa;
  /* margin-left: 30px; */
  margin-top: -10px;
  margin-bottom: 15px;
}

div.filter-buttons > button {
  color: #292929 !important;
  text-align: center;
  font-family: Inter !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  letter-spacing: 1.6px;
}

.card-title-label {
  color: #828282;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
}

.card-title {
  color: #000;
  /* font-family: "Inter"; */
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.92px;
}

.card-title-section {
  /* margin-top: 15px; */
  /* margin-left: -14px; */
}

.card-detail-label {
  color: #828282;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.card-detail {
  color: #000;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.96px;
}

.imgfull a:hover {
  color: white;
}

.pagination-link,
.pagination-previous,
.pagination-next {
  background-color: white;
}

.box {
  /* width: 100%; */
  padding: 5px !important;
}

/* .background-image-style {
  width: 486px;
  width: 100%;
} */

.card-button {
  width: 48%;
  border: 0.5px solid #3e8ce8;
  border-radius: 7px;
  color: #3e8ce8;
  font-family: Arial;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.fa-bars:before,
.fa-navicon:before {
  color: rgb(255, 246, 35);
  font-size: 25px;
}
.progress-card {
  width: 100%;
  padding: 5px 20px;
  text-align: center;
}
.navbar-brand {
  display: none;
}
.card-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.edit-form {
  padding: 20px;
}
.pagination-container {
  display: flex;
  justify-content: center;
}
/* .column {
  margin-left: 35px;
} */
.progress-card-sub {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
}
.select {
  z-index: 0;
}
.navbar-menu {
  background-color: transparent;
  box-shadow: none;
}
.is-ancestor {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
}
.campaigns-section {
  font-size: 15px;
  margin-left: 55px;
}
/* .column-flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */
.campaign-form-align {
  margin-left: 360px;
}
.all-campaigns-align {
  margin-left: 55px;
}
.brand-applications-align {
  margin-left: 70px;
  margin-bottom: 20px;
}
.user-roles-align {
  margin-left: 60px;
}
.file-cta {
  width: 100%;
}
.width-100-pct {
  width: 100%;
}
.font-weight-800 {
  font-weight: 800;
}
.insta-insights-cta {
  display: flex;
  flex-direction: column;
  /* background-color: #e4e4e4; */
  border: none;
  color: #4a4a4a;
  padding: 0;
}
.insta-insights-cta:hover {
  color: #3273dc;
}
.insta-insights-cta:focus:not(:active) {
  /* border: none; */
  box-shadow: none;
}
::-ms-reveal {
  display: none;
}
@media (max-width: 1216px) {
  .campaign-form-align {
    margin: auto;
  }
  .all-campaigns-align {
    margin-left: 45px;
  }
  .brand-applications-align {
    margin-left: 120px;
  }
  .user-roles-align {
    margin-left: 45px;
  }
  .campaigns-section {
    margin-left: 44px;
  }
  .column {
    margin-left: 0px;
  }
  /* .box {
    max-width: 486px;
    width: 100%;
  } */
  .container {
    width: 100%;
    padding: 0px 4px;
  }
  /* .background-image-style {
    max-width: 486px;
    width: 100%;
  } */
  .card-button {
    font-size: 14px !important;
  }
  .subtitle.is-3 {
    font-size: 25px;
    margin-top: 5px !important;
  }
  .card-title {
    font-size: 20px;
  }
  .card-detail-label {
    font-size: 10px;
  }
  .card-detail {
    font-size: 12px;
  }
  /* .pagination-next {
    flex-grow: 0;
  }
  .pagination {
    margin: 0px !important;
  } */
  .columns {
    margin: 0px 0px -0.75rem;
  }
  .column {
    padding: 0.75rem 0px;
  }
  .menu1 {
    /* padding: 5px; */
  }
  .logo {
    width: 45px !important;
  }
  .filter-buttons {
    height: 40px;
    /* width: 302px; */
    /* margin-left: 2px; */
  }
  div.filter-buttons > button {
    font-size: 12px;
  }
  .dual-campaign-container {
    /* margin-top: -58px; */
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
  }
  /* .campaigns-container {
    overflow-y: scroll;
    height: 100vh;
  } */
  .progress-card {
    padding: 5px 0px;
  }
  .progress-card-sub {
    font-size: 10px;
  }
  .hamburger {
    visibility: visible;
  }
  .card-content {
    padding: 0px;
  }
}
@media (max-width: 800px) {
  .campaign-item {
    width: 100%;
  }
}
